import React from "react";
import Link from "next/link";
import MenuItem from "@mui/material/MenuItem";

const DropdownMenuItem: React.FC<CommonHeader.IDropdownMenuItemProps> = ({
  route,
  handleClose,
  label,
  target,
}) => {
  return (
    <Link href={route}>
      <a target={target}>
        <MenuItem onClick={handleClose}>{label}</MenuItem>
      </a>
    </Link>
  );
};

export default DropdownMenuItem;
