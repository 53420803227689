import React from "react";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";

const DropdownMenu: React.FC<CommonHeader.IDropdownMenuProps> = ({
  anchorEl,
  handleClose,
  sx,
  ...other
}) => {
  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      autoFocus={false}
      disableScrollLock={true}
      TransitionComponent={Fade}
      sx={{
        // disable pointer events on the overlay
        pointerEvents: "none",
        "& .MuiListItem-button:hover": {
          backgroundColor: "rgba(68, 146, 76, 0.04)",
        },
        // enable pointer events on the popover menu
        "& .MuiPopover-paper": {
          pointerEvents: "initial",
          boxShadow: "rgb(0 0 0 / 15%) 0px 1.5px 3px",
          mt: 3,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
        ...(sx || {}),
      }}
      {...other}
    />
  );
};

export default DropdownMenu;
