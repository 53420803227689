import React from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";

import Logout from "../Logout";
import DropdownMenuItem from "./common/DropdownMenuItem";
import DropdownMenu from "./common/DropdownMenu";
import { profileData } from "../headerData";
import useUserContext from "../../../../context/get-user/useUserContext";
import HttpError from "@packages/common/src/components/error-handlers/HttpError";

const Profile: React.FC<CommonHeader.IMenuProps> = ({
  anchorEl,
  handleClose,
  ...other
}) => {
  const { user, responseError } = useUserContext();
  return (
    <>
      {responseError !== null ? (
        <HttpError axiosError={responseError} />
      ) : (
        user !== null && (
          <DropdownMenu
            anchorEl={anchorEl}
            handleClose={handleClose}
            {...other}
          >
            <ListItem divider disableGutters>
              <Box px={4}>
                <Typography variant="subtitle1">
                  {!!user?.username ? user?.username : ""}
                </Typography>
                <Typography variant="body1">
                  {!!user?.email ? user?.email : ""}
                </Typography>
              </Box>
            </ListItem>
            <ListItem divider disableGutters>
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                sx={{ "& a": { textDecoration: "none" } }}
              >
                <DropdownMenuItem
                  handleClose={handleClose}
                  label="Profile"
                  route={`/profile/${user?.username}`}
                />
                {profileData.map(({ id, ...props }) => (
                  <DropdownMenuItem
                    key={id}
                    handleClose={handleClose}
                    {...props}
                  />
                ))}
                {!!user?.has_dphi_business_organization && (
                  <DropdownMenuItem
                    handleClose={handleClose}
                    label="Organizer Dashboard"
                    route="/organizer/dashboard"
                  />
                )}
              </Box>
            </ListItem>
            <Logout />
          </DropdownMenu>
        )
      )}
    </>
  );
};

export default Profile;
