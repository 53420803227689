import React from "react";
import { useRouter } from "next/router";
import { AxiosError } from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material";

import { FRONTEND_LOGIN_URL } from "../../../utils/constants";
import useDialog from "@packages/skillspace/src/common/components/dialog-provider/useDialog";
import { getErrorMessage } from "../../../utils/error";
import { getCookie } from "../../../service/utils";
import { csrfCookieName } from "../../../utils/utils";

const Logout = () => {
  const theme = useTheme();
  const router = useRouter();
  const { displayDialog } = useDialog();

  const csrftoken: unknown = getCookie(csrfCookieName);

  const handleLogout = async (e: any) => {
    e.preventDefault();
    try {
      const accountsService = new (
        await import("../../../../accounts/service/accountsService")
      ).default();
      await accountsService.logout();
      router.push(FRONTEND_LOGIN_URL);
    } catch (error) {
      displayDialog({
        title: "Error!",
        content: "",
        variant: "error",
        agreeText: "Retry",
        onAgree: (closeDialog: any) => {
          closeDialog();
        },
        onDisagree: () => {},
        children: (
          <Typography variant="subtitle2">
            {getErrorMessage((error as AxiosError).response?.data)}
          </Typography>
        ),
      });
    }
  };

  return (
    <Box component="form" onSubmit={handleLogout}>
      <input
        type="hidden"
        name="csrfmiddlewaretoken"
        value={typeof csrftoken === "string" ? csrftoken : ""}
      />
      <MenuItem component="button" sx={{ width: "100%" }} type="submit">
        Logout
      </MenuItem>
    </Box>
  );
};

export default Logout;
