import {
  FRONTEND_ABOUT_US_URL,
  FRONTEND_BOOTCAMPS_URL,
  FRONTEND_CHALLENGES_URL,
  FRONTEND_COURSES_URL,
  FRONTEND_DASHBOARD_URL,
  FRONTEND_DISCUSS_URL,
  FRONTEND_LIVE_SESSIONS_URL,
  FRONTEND_LOGIN_URL,
  FRONTEND_NOTEBOOKS_URL,
  FRONTEND_PROFILE_EDIT_URL,
  FRONTEND_SIGNUP_URL,
  FRONTEND_WALL_OF_LOVE_URL,
} from "../../../utils/constants";

export const sideBarAppRoutes = [
  // {
  //   id: 2,
  //   label: "AI Planet For Teams",
  //   route: "/teams",
  // },
  {
    id: 10,
    label: "About Us",
    route: FRONTEND_ABOUT_US_URL,
  },
  {
    id: 7,
    label: "Discuss",
    route: FRONTEND_DISCUSS_URL,
    target: "_blank",
  },
];

export const sidebarNavActions = [
  {
    id: 1,
    label: "Login",
    route: FRONTEND_LOGIN_URL,
  },
  {
    id: 2,
    label: "Join For Free",
    route: FRONTEND_SIGNUP_URL,
  },
];

export const profileData = [
  {
    id: 2,
    label: "Dashboard",
    route: FRONTEND_DASHBOARD_URL,
  },
  {
    id: 3,
    label: "Additional Settings",
    route: FRONTEND_PROFILE_EDIT_URL,
  },
];

export const forIndividuals: CommonHeader.IDropdownDataProps[] = [
  {
    id: 1,
    label: "Courses",
    route: FRONTEND_COURSES_URL,
  },
  {
    id: 2,
    label: "Challenges",
    route: FRONTEND_CHALLENGES_URL,
  },
  // {
  //   id: 3,
  //   label: "Notebooks",
  //   route: FRONTEND_NOTEBOOKS_URL,
  // },
  {
    id: 4,
    label: "Live Sessions",
    route: FRONTEND_LIVE_SESSIONS_URL,
  },
  {
    id: 5,
    label: "Bootcamps",
    route: FRONTEND_BOOTCAMPS_URL,
  },
  {
    id: 6,
    label: "Learning Paths",
    route: "/learning-paths",
  },
  {
    id: 7,
    label: "Wall Of Love",
    route: FRONTEND_WALL_OF_LOVE_URL,
  },
];

export const forIndustry: CommonHeader.IDropdownDataProps[] = [
  {
    id: 1,
    label: "Education & EdTech",
    route: "https://aimarketplace.co/ai-in-education",
    target: "_blank",
  },
  {
    id: 2,
    label: "Healthcare",
    route: "https://aimarketplace.co/ai-in-healthcare",
    target: "_blank",
  },
  {
    id: 3,
    label: "Ecommerce & Retail",
    route: "https://aimarketplace.co/ai-in-ecommerce-and-retail",
    target: "_blank",
  },
  {
    id: 4,
    label: "Supply Chain",
    route: "https://aimarketplace.co/ai-in-supply-chain",
    target: "_blank",
  },
  {
    id: 5,
    label: "Banking & Fintech",
    route: "https://aimarketplace.co/banking-fintech",
    target: "_blank",
  },
];
