import React, { useState } from "react";

const useAnchorEl = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleAnchorEl = (
    event: React.MouseEvent<HTMLButtonElement> | undefined
  ) => {
    if (anchorEl === null && event) handleClick(event);
    else handleClose();
  };

  return { anchorEl, handleClick, handleClose, toggleAnchorEl };
};

export default useAnchorEl;
